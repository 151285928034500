@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;

  padding-bottom: 90px;
  background: #f5f5f5;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  padding: 0 15px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
  border-radius: 15px;
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.2 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);
    border-radius: 15px;

    &:hover {
      /* transform: scale(1.005); */
      /* box-shadow: var(--boxShadowListingCard); */
    }
  }
}

.SectionPhotosMaybe {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
    margin-bottom: 50px;
  }
}

.SectionPhotosImages {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: flex-start;
  margin: 28px 0;
}
.eachImagesImage {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 15px;
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);
  border-radius: 15px;

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  } */

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1356px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1358px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1356px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px); */
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 31px;
    justify-content: space-between;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  /* display: none; */

  /* @media (--viewportMedium) { */
  display: inline;
  /* } */
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  min-width: 140px;
  padding: 10px;
  border: none;
  color: black;
  background: #dadada;

  border-radius: 26px;
  font-size: 0.8rem;
  font-weight: 600;
  &:hover {
    /* text-decoration: underline; */
    cursor: pointer;
    background: #999999;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.sectionPrice {
  composes: sectionDescription;
}
.sectionSocials {
  composes: sectionDescription;
}
.descriptionTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDark);
  font-size: 24px;

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDark);
  font-size: 24px;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDark);
  font-size: 24px;

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 50px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.firstSection {
  display: flex;
  flex-wrap: wrap;
}
.firstSection .sectionPrice {
  width: 100%;

  @media (--viewportLarge) {
    width: 50%;
    padding-right: 20px;

    margin-bottom: 50px;
  }
}
.firstSection .sectionMap {
  width: 100%;

  @media (--viewportLarge) {
    width: 50%;
    padding-left: 20px;

    margin-bottom: 50px;
  }
}
.secondSection {
  display: flex;
  flex-wrap: wrap;
}
.secondSection .sectionDescription {
  width: 100%;

  @media (--viewportLarge) {
    margin-bottom: 50px;
    width: 50%;
    padding-right: 20px;
  }
}
.secondSection .sectionSocials {
  width: 100%;
  @media (--viewportLarge) {
    margin-bottom: 50px;
    width: 50%;
    padding-left: 20px;
  }
}
.reviewsHeading {
  composes: h3 from global;
  color: var(--matterColorDark);
  font-size: 24px;

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorDark);
  font-size: 24px;
  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;
  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    /* height: 75vh; */
    height: 400px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.star {
  fill: var(--attentionColor);
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}
.age {
  margin-left: 0.25rem;
  color: var(--attentionColor);
  font-size: 14px;
}
.sectionPricing {
  display: flex;
  margin: 28px 0;

  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 16px;
}
.eachPriceCard {
  border: none;
  border-radius: 15px;
  /* max-height: 500px; */
  min-height: 400px;
  min-width: 250px;
  width: 100%;
  margin-bottom: 35px;
  padding: 25px;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    max-width: 300px;

    width: 42%;
  }
}

.priceIcon {
  margin-right: 15px;
  font-size: 1.3rem;
}
.cardType {
  margin: 5px 0;
}
.priceCardPrice {
  margin-top: 25px;
  /* float: right; */
  border-radius: 50%;
  padding: 15px;
  border: 2px solid #6f00de;
  cursor: pointer;
  font-weight: 700;
  align-self: flex-end;
}
.descriptionSection {
  border: none;
  border-radius: 15px;
  /* max-height: 500px; */
  margin: 28px 0;

  min-height: 200px;
  width: 100%;
  margin-bottom: 35px;
  padding: 25px;

  padding-left: 0;
  background-color: #fff;
  width: 100%;
  /* max-width: 400px; */
  overflow: auto;
  @media (--viewportLarge) {
    padding: 25px;

    max-width: 800px;

    /* width: 32%; */
  }
}
.descIcon {
  font-size: 1.8rem;
  color: #6f00de;
  width: 80px;
}
.descriptionSecDesc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.descText {
  width: 75%;
}
.descText p {
  padding-top: 0;
  margin-top: 0;
  font-weight: 700;
  word-break: break-all;
}
.social {
  width: fit-content;
  min-width: 200px;
  margin: 28px 10px;
  /* padding:  30px 40px; */
  height: 55px;
  @media (--viewportLarge) {
  }
}



.socailButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background: white;
  border: none;
  border-radius: 15px;
  padding-left: 25px;
  padding-right: 25px;
  color: #6f00de;
  cursor: pointer; /* Cursor like a hyperlink */


  font-weight: var(--fontWeightBold);
  @media (--viewportLarge) {
    padding-left: 25px;
  }
}

.socailButton:hover {
  color: darkpurple; /* Darker color on hover/focus for visual feedback */
  outline: none; /* Remove focus outline if desired */
  cursor: pointer;
}
.socailButton a:hover {
  text-decoration: none;
}
.socialicon {
  color: #6f00de;
  font-size: 1.3rem;
  margin-right: 25px;

  @media (--viewportLarge) {
  }
}
.locationText {
  margin-top: 15px;
}
.locationText b {
  padding-bottom: 0;
  margin-bottom: 0;
}
.locationText p {
  padding-top: 0;
  width: 350px;
  margin-top: 0;
}

.getAQuoteLink {
  composes: contactLink;
  background-color: var(--marketplaceColor);
  color: white;
  &:not(:disabled):hover {
    background-color: var(--marketplaceColorDark);
  }
  &:disabled {
    pointer-events: none;
    color: #dadada;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 1rem;

  margin-left: 1.5rem;
  @media (--viewportMedium) {
    margin-left: 0;
  }
}

.disabledWrapper {
  cursor: not-allowed;
}

.selectButton {
  margin-top: 1rem;
  composes: getAQuoteLink;
  font-size: 1rem;
}

.locationLoading {
  font-size: 14px;
  font-weight: normal;
  color: #FFA500;
  font-style: italic;
}
